(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name statistics.teams.controller:StatsTeamsCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.statistics.neoindi')
    .controller('NeoindiCtrl',NeoindiCtrl);

  function NeoindiCtrl($filter, $mdToast,$scope) {
    var vm = this;
    vm.ctrlName = 'NeoindiCtrl';
    vm.us='cebllob';
    vm.pass='cebllob';
   
  }
}());
